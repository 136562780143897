<template>
    <section class="community-section">
        <h1 class="h0">
            Key Benefits for Creators
        </h1>
        <div class="row align-items-center">
            <div class="col">
                <div class="community-text">
                    <p>Memo'd helps you organize and publish your ideas. It's a quick and easy medium to establish yourself as a thought leader in any given field or industry.</p>
                    <p>We will strategically position your writing on the app in order to reach a global audience and build a new following.</p>
                    <p>You can earn income through the tipping system as well as showcase your other published works to a whole new potential customer base.</p>
                </div>
            </div>
            <div class="col d-none d-xl-flex">
                <img src="@assets/img/founders-landing/community/community-graphic.svg" alt="Community">
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "CommunitySection"
}
</script>

<style lang="scss" scoped>
.community-section {
    padding: 50px 0;

    @media (max-width: $md) {
        padding: 30px 0;
    }

    @media(max-width: $xl) {
        background-image: url("~@assets/img/founders-landing/community/community-graphic-mobile.svg");
        background-repeat: no-repeat;
        background-position: center;
    }

    h1 {
        text-align: center;
        color: $primary-color;

        @media(max-width: $xl) {
            text-align: center;
        }

        @media(max-width: $sm) {
            font-size: 24px;
            line-height: 50px;
        }
    }

    .community-text {
        margin-right: 80px;

        p {
            position: relative;
            font-size: 20px;
            line-height: 38px;
            font-weight: 400;
            margin-bottom: 40px;

            &::before {
                content: "";
                position: absolute;
                top: 10px;
                width: 22px;
                height: 22px;
                background-image: url("~@assets/img/founders-landing/more-features/bullet.svg");
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                margin-left: -40px;
                border-radius: 100%;
            }
        }

        @media(max-width: $xl) {
            margin-right: 0;
            padding: 200px 20px;
        }

        @media(max-width: $lg) {
            padding: 0 20px;
            text-align: center;

            p {
                font-size: 18px;
            }
        }

    }
}
</style>
